export function createMeta ({
  title,
  description,
  image,
  sharingDescription,
  sharingTitle,
  keywords
}) {
  const currentSharingDescription = sharingDescription || description
  const currentSharingTitle = sharingTitle || title
  const meta = [
    { hid: 'og:title', property: 'og:title', content: currentSharingTitle },
    {
      hid: 'twitter:title',
      name: 'twitter:title',
      content: currentSharingTitle
    },
    {
      hid: 'og:url',
      property: 'og:url',
      content: this.$config.WWW_HOST + this.$route.path
    }
  ]

  if (description) {
    meta.push({
      hid: 'description',
      name: 'description',
      content: description
    })
    meta.push({
      hid: 'twitter:description',
      name: 'twitter:description',
      content: currentSharingDescription
    })
    meta.push({
      hid: 'og:description',
      property: 'og:description',
      content: currentSharingDescription
    })
  }

  if (image) {
    meta.push({ hid: 'og:image', property: 'og:image', content: image })
    meta.push({ hid: 'twitter:image', name: 'twitter:image', content: image })
  }

  if (keywords) {
    meta.push({ name: 'keywords', content: keywords })
  }

  return {
    title,
    meta
  }
}
