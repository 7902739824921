<script>
export default {
  name: 'PageArticle',

  props: {
    content: {
      type: String,
      required: true
    }
  }
}
</script>

<template>
  <div :class="$style.article">
    <div v-html="content" />
  </div>
</template>

<style lang="scss" module>
.article {
  width: 100%;
  margin: 12px 0;
  padding: 24px 12px;
  color: $white;
  background: $blue-grey70;
  border-radius: 8px;

  h2 {
    font-size: 14px;
    line-height: 120%;
    font-weight: 700;
    margin: 12px 0;
  }

  h3 {
    font-size: 13px;
    line-height: 120%;
    font-weight: 700;
    margin: 12px 0;
  }

  h4 {
    font-size: 12px;
    line-height: 120%;
    font-weight: 700;
    margin: 12px 0;
  }

  p {
    font-size: 12px;
    line-height: 100%;
    font-weight: 400;
    margin: 8px 0 4px;
  }

  ul, ul>li {
    font-size: 12px;
    list-style-type: circle;
    list-style-position: inside;
  }

  ol, ol>li {
    font-size: 12px;
    list-style-type: decimal;
    list-style-position: inside;
  }
}
</style>
