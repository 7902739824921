// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".article_kP8M4{width:100%;margin:12px 0;padding:24px 12px;color:#fff;background:#1c2735;border-radius:8px}.article_kP8M4 h2{font-size:14px;line-height:120%;font-weight:700;margin:12px 0}.article_kP8M4 h3{font-size:13px;line-height:120%;font-weight:700;margin:12px 0}.article_kP8M4 h4{font-size:12px;line-height:120%;font-weight:700;margin:12px 0}.article_kP8M4 p{font-size:12px;line-height:100%;font-weight:400;margin:8px 0 4px}.article_kP8M4 ul,.article_kP8M4 ul>li{font-size:12px;list-style-type:circle;list-style-position:inside}.article_kP8M4 ol,.article_kP8M4 ol>li{font-size:12px;list-style-type:decimal;list-style-position:inside}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"article": "article_kP8M4"
};
module.exports = ___CSS_LOADER_EXPORT___;
