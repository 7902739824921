// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".main_hKI1a{position:relative;padding:2px 0}.tabs_6x1qI{position:relative;overflow:auto;width:100%;padding:0 16px;overflow-x:auto}.tabs_6x1qI::-webkit-scrollbar{width:0;outline:none;padding:0}.tabs_6x1qI::-webkit-scrollbar-track{width:8px;background:rgba(0,0,0,0);padding:0 4px}.tabs_6x1qI::-webkit-scrollbar-thumb{background:rgba(0,0,0,0);border-radius:2px;border-left:4px solid rgba(0,0,0,0);border-right:4px solid rgba(0,0,0,0)}.tabs__container_VfxHP{display:flex;align-items:center;justify-content:flex-start;width:max-content;overflow:hidden}.background_FYz37{position:absolute;top:0;left:-100vw;width:200vw;height:64px;background:#1c2735;border:1px solid #2b3848;z-index:-1}.tab_FMe6W{padding:20px;color:#90a4ae;font-size:18px;font-weight:500;line-height:22px;margin-right:8px;cursor:pointer}.tabActive_FOvX6{position:relative;color:#fff}.tabActive_FOvX6:after{position:absolute;content:\"\";bottom:0;left:0;width:100%;height:2px;background:#ffb300;border-radius:8px 8px 0px 0px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": "main_hKI1a",
	"tabs": "tabs_6x1qI",
	"tabs__container": "tabs__container_VfxHP",
	"background": "background_FYz37",
	"tab": "tab_FMe6W",
	"tabActive": "tabActive_FOvX6"
};
module.exports = ___CSS_LOADER_EXPORT___;
