// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container_n2J6g{display:flex;align-items:flex-start;justify-content:space-between;width:100%}@media(min-width: 1300px){.container_n2J6g{flex-direction:row-reverse}}.fake_WWSg8{height:1px;width:100%;background:rgba(0,0,0,0);scroll-margin:140px}.list_erM7T{display:grid;grid-template-columns:1fr;grid-gap:16px;width:100%}@media(min-width: 768px){.list_erM7T{grid-template-columns:1fr 1fr}}@media(min-width: 1300px){.list_erM7T{grid-template-columns:1fr 1fr 1fr}}.alphabet_qer7v{position:sticky;top:120px;display:grid;grid-template-columns:1fr;grid-gap:2px;width:12px;margin-left:18px;color:#90a4ae;cursor:pointer}@media(min-width: 768px){.alphabet_qer7v{top:158px}}@media(min-width: 1300px){.alphabet_qer7v{margin-left:0;margin-right:40px}}.alphabet__letter_KChUY{font-size:12px;line-height:105%;font-weight:500}@media(min-width: 1300px){.alphabet__letter_KChUY{font-size:14px;line-height:20px}}.notFound_dJD3b{width:100%;display:flex;flex-direction:column;align-items:center;justify-content:center;color:#fff}@media(min-width: 768px){.notFound_dJD3b{grid-column:1/-1}}.notFound__icon_Hh7BL{width:48px;height:48px}.notFound__text_l0iB1{margin-top:16px;font-size:16px;font-weight:700;line-height:22px}.item_tIclm{background:#1c2735;border-radius:8px}.itemAdd_mBA0W{display:flex;align-items:center;justify-content:center;color:#90a4ae;background:#2b3848;border-radius:8px;padding:12px;font-size:24px;line-height:24px;font-weight:500;cursor:pointer}.itemAdd_mBA0W:hover{background:#334153}.itemAdd__icon_Qhqxo{width:32px;height:32px;margin-right:16px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container_n2J6g",
	"fake": "fake_WWSg8",
	"list": "list_erM7T",
	"alphabet": "alphabet_qer7v",
	"alphabet__letter": "alphabet__letter_KChUY",
	"notFound": "notFound_dJD3b",
	"notFound__icon": "notFound__icon_Hh7BL",
	"notFound__text": "notFound__text_l0iB1",
	"item": "item_tIclm",
	"itemAdd": "itemAdd_mBA0W",
	"itemAdd__icon": "itemAdd__icon_Qhqxo"
};
module.exports = ___CSS_LOADER_EXPORT___;
