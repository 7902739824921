<script>
export default {
  name: 'Tabs',

  props: {
    activeTabId: {
      type: String,
      required: true
    },

    tabs: {
      type: Array,
      required: true
    },

    noBg: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    tabStyles ({ id }) {
      return {
        [this.$style.tab]: true,
        [this.$style.tabActive]: id === this.activeTabId
      }
    },

    tabName ({ title }) {
      return title
    },

    openTab ({ id }) {
      this.$emit('setActiveTab', id)
    }
  }
}
</script>

<template>
  <div :class="$style.main">
    <div v-if="!noBg" :class="$style.background" />
    <div :class="$style.tabs">
      <div v-if="tabs" :class="$style.tabs__container">
        <div v-for="tab in tabs" :key="tab.id">
          <div :class="tabStyles(tab)" @click="openTab(tab)">
            {{ tabName(tab) }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" module>
.main {
  position: relative;
  padding: 2px 0;
}

.tabs {
  position: relative;
  @include silent-scroll;

  width: 100%;
  padding: 0 16px;
  overflow-x: auto;

  &__container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: max-content;
    overflow: hidden;
  }
}

.background {
  position: absolute;
  top: 0;
  left: -100vw;
  width: 200vw;
  height: 64px;
  background: $blue-grey70;
  border: 1px solid $blue-grey50;
  z-index: -1;
}

.tab {
  padding: 20px;
  color: $blue-grey10;
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  margin-right: 8px;
  cursor: pointer;

  &Active {
    position: relative;
    color: $white;

    &:after {
      position: absolute;
      content: '';
      bottom: 0;
      left: 0;
      width: 100%;
      height: 2px;
      background: $amber60;
      border-radius: 8px 8px 0px 0px;
    }
  }
}
</style>
